import { Avatar, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import UserPic from "../../assets/images/dummy-user.svg";
import NotificationIco from "../../assets/images/notification-ico.svg";
import LanguageIco from "../../assets/images/language-ico.svg";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import Languages from "./Languages";

function Navbar() {
  const { t } = useTranslation();

  const [isNotificaitonOpen, setIsNotificationOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);

  return (
    <Flex gap="24px" w="100%" flexDir="column">
      <Flex justify="space-between" align="center" gap="24px" w="100%">
        <CustomText
          text={t("welcomeText")}
          family="Titillium Web"
          size="28px"
          weight="700"
          color="extsy.neutral50"
          lh="36px"
        />
        <Flex gap="16px" align="center">
          <Flex align="center" gap="8px">
            <Avatar
              src={UserPic}
              name="SA"
              boxSize="48px"
              bg="extsy.g1"
              color="extsy.bg"
            />
            <Flex flexDir="column" gap="6px">
              <CustomText
                text="Serra Alexandra"
                color="extsy.baseWhite"
                weight="600"
              />
              <Flex align="center" gap="8px">
                {/* <IconShield size="16px" color="#fff" /> */}
                <CustomText text="user00@email.com" size="12px" weight="400" />
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" gap="8px">
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              height="48px"
              width="48px"
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              onClick={() => {
                setIsNotificationOpen(false);
                setIsLangOpen(!isLangOpen);
              }}
            >
              <Image src={LanguageIco} alt="lang" boxSize="24px" />
              {isLangOpen && <Languages />}
            </Flex>
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              height="48px"
              width="48px"
              bg="extsy.neutral1000"
              borderRadius="99px"
              border="1px solid"
              borderColor="extsy.neutral900"
              cursor="pointer"
              pos="relative"
              onClick={() => {
                setIsNotificationOpen(!isNotificaitonOpen);
                setIsLangOpen(false);
              }}
            >
              <Image src={NotificationIco} alt="notification" boxSize="24px" />
              {isNotificaitonOpen && <Notifications />}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Navbar;
