import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Header/Navbar";

function DashboardLayout() {
  const navigate = useNavigate();
  const [isLessThan1920] = useMediaQuery("(max-width: 1919px)");

  useEffect(() => {
    if (isLessThan1920) {
      navigate("/coming-soon");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLessThan1920]);

  return (
    <Flex
      p="24px"
      gap="48px"
      alignItems="flex-start"
      bg="extsy.bg"
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
    >
      <Sidebar />
      <Flex gap="24px" flexDir="column" width="100%">
        <Navbar />
        <Box overflowY="auto" maxH="calc(100vh - 120px)">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardLayout;
