import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
// import AvailableBalanceCard from "../../components/cards/AvailableBalanceCard";
// import OrderNowCard from "../../components/cards/OrderNowCard";
// import RecentTransactions from "../../components/dashboard/RecentTransactions";
// import SwapWidget from "../../components/dashboard/SwapWidget";

function Dashboard() {
  //   const [isVerified, setIsVerified] = useState(false);
  return (
    <Grid
      gap="24px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem colSpan={8}>{/* <AvailableBalanceCard /> */}</GridItem>
      <GridItem colSpan={4}>
        {/* <OrderNowCard isVerified={isVerified} setIsVerified={setIsVerified} /> */}
      </GridItem>
      <GridItem colSpan={8}>
        {/* <RecentTransactions */}
        {/* minH={"401px"} */}
        {/* mt={isVerified ? "-144px" : "-108px"} */}
        {/* /> */}
      </GridItem>
      <GridItem colSpan={4}>{/* <SwapWidget /> */}</GridItem>
    </Grid>
  );
}

export default Dashboard;
