import { Box, Input } from "@chakra-ui/react";
import { IconSearch } from "@tabler/icons-react";
import React from "react";

function TableSearchInput({ placeholder, minW }: any) {
  return (
    <Box pos="relative">
      <IconSearch
        color="#fff"
        size="16px"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          marginLeft: "12px",
          marginTop: "10px",
        }}
      />
      <Input
        pr="16px"
        py="4px"
        pl="32px"
        minW={minW || "250px"}
        height="38px"
        borderRadius="999px"
        borderWidth="1px solid"
        borderColor="extsy.neutral900"
        fontSize="12px"
        color="extsy.baseWhite"
        fontFamily="Metropolis"
        _placeholder={{
          color: "extsy.neutral700",
          fontFamily: "Metropolis",
        }}
        placeholder={placeholder}
        _hover={{
          borderColor: "extsy.primary100",
        }}
        _focusVisible={{
          borderColor: "extsy.primary100",
        }}
      />
    </Box>
  );
}

export default TableSearchInput;
